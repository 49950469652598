import React from 'react'
import { Link, VisuallyHidden } from '@overdose/components'
import { IconArrowRight } from '@tabler/icons-react'
import classNames from 'classnames'
import { Image } from '~/components/Image'
import { SectionContainer } from '~/components/SectionContainer'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { getImageSrc } from '~/helpers'
import styles from './FourZeroFour.module.scss'
import { FourZeroFourProps } from './FourZeroFour.types'

const renderContent = (
  headingText,
  title,
  paragraph,
  links,
  isMultiColumnLayout
) => {
  return (
    <>
      <VisuallyHidden>404</VisuallyHidden>
      <div className='text-center grid gap-4'>
        <Typography
          tag={TypographyTag.h2}
          variant={TypographyVariant.Heading3}
          className={classNames('!text-white', styles.heading)}>
          {headingText}
        </Typography>
        <Typography
          tag={TypographyTag.h4}
          variant={TypographyVariant.Heading5}
          className='!text-white'>
          {title}
        </Typography>
        <Typography
          tag={TypographyTag.p}
          variant={TypographyVariant.BodyRegularExtraLineHeight}
          className={styles.para}>
          {paragraph}
        </Typography>
      </div>
      {links?.length > 0 && (
        <div
          className={classNames(
            'grid grid-cols-2 gap-x-4',
            isMultiColumnLayout && 'md:grid-cols-1'
          )}>
          {links.map((action) => {
            return (
              <Link
                className={classNames(
                  'flex items-center justify-between w-full p-4',
                  styles.link
                )}
                to={action.href}
                key={action.title}
                title={action.title}>
                <Typography
                  className='text-white'
                  tag={TypographyTag.p}
                  variant={TypographyVariant.BodyRegularBold}>
                  {action.title}
                </Typography>
                <IconArrowRight size={24} color='#fff' />
              </Link>
            )
          })}
        </div>
      )}
    </>
  )
}

const FourZeroFour = ({
  headingText,
  title,
  paragraph,
  links,
  width = 'full',
  desktopBackgroundVideo,
  tabletBackgroundVideo,
  mobileBackgroundVideo,
  backgroundImage,
  isMultiColumnLayout,
}: FourZeroFourProps) => {
  return (
    <SectionContainer width={width} innerClassName='overflow-hidden'>
      {isMultiColumnLayout ? (
        <div className={classNames(styles.root, 'md:flex')}>
          <div className={classNames('relative w-full', styles.imageContainer)}>
            {backgroundImage?.src && (
              <Image
                className='object-cover'
                src={getImageSrc(backgroundImage?.src, '500')}
                addSrcSet={false}
                alt={backgroundImage?.altText}
                fill
              />
            )}
          </div>
          <div
            className={classNames(
              'flex flex-col bg-background-primary-muted gap-6 md:gap-8',
              styles.multiColContentContainer
            )}>
            {renderContent(
              headingText,
              title,
              paragraph,
              links,
              isMultiColumnLayout
            )}
          </div>
        </div>
      ) : (
        <div
          className={classNames(
            styles.root,
            'relative h-screen grid place-content-center px-4 md:px-0'
          )}>
          {backgroundImage?.src && (
            <Image
              className='object-cover'
              src={getImageSrc(backgroundImage?.src, '1200')}
              addSrcSet={false}
              sources={
                <>
                  <source
                    srcSet={getImageSrc(backgroundImage?.src, '500')}
                    media='(max-width: 500px)'
                  />
                  <source
                    srcSet={getImageSrc(backgroundImage?.src, '800')}
                    media='(max-width: 800px)'
                  />
                  <source
                    srcSet={getImageSrc(backgroundImage?.src, '1000')}
                    media='(max-width: 1000px)'
                  />
                </>
              }
              alt={backgroundImage?.altText}
              fill
            />
          )}
          {desktopBackgroundVideo && (
            <video
              autoPlay
              loop
              muted
              className='hidden 2xl:block absolute inset-0 w-full h-full object-cover'
              poster={getImageSrc(desktopBackgroundVideo?.poster?.src, '500')}>
              <track kind='captions' srcLang='en' label='English' />
              {desktopBackgroundVideo.sources.map(({ src, type }, index) => {
                return <source src={src} type={type} key={index} />
              })}
            </video>
          )}
          {tabletBackgroundVideo && (
            <video
              autoPlay
              loop
              muted
              className='hidden sm:block 2xl:hidden absolute inset-0 w-full h-full object-cover'
              poster={getImageSrc(tabletBackgroundVideo?.poster?.src, '500')}>
              <track kind='captions' srcLang='en' label='English' />
              {tabletBackgroundVideo.sources.map(({ src, type }, index) => {
                return <source src={src} type={type} key={index} />
              })}
            </video>
          )}
          {mobileBackgroundVideo && (
            <video
              autoPlay
              loop
              muted
              className='block sm:hidden absolute inset-0 w-full h-full object-cover'
              poster={getImageSrc(tabletBackgroundVideo?.poster?.src, '500')}>
              <track kind='captions' srcLang='en' label='English' />
              {mobileBackgroundVideo.sources.map(({ src, type }, index) => {
                return <source src={src} type={type} key={index} />
              })}
            </video>
          )}
          <div
            className={classNames(
              'flex flex-col text-white text-center z-10 lg:gap-6 2xl:gap-10 3xl:gap-8',
              styles.contentContainer
            )}>
            {renderContent(
              headingText,
              title,
              paragraph,
              links,
              isMultiColumnLayout
            )}
          </div>
        </div>
      )}
    </SectionContainer>
  )
}

export { FourZeroFour }
